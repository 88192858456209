<template>
    <v-container fluid>
        <v-row justify='space-between'>
            <v-col cols="8">
                <h1>Instructor Management</h1>
            </v-col>
            <v-col class="text-right">
                <v-row justify='end' align-end class="mr-1">
                    <v-space></v-space>
                    <v-dialog
                        
                        v-model="dialog"
                        scrollable
                        max-width="400px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="mt-5"
                                color="success"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                            <v-icon>mdi-plus</v-icon>
                            เพิ่มผู้สอน
                            </v-btn>
                        </template>
                        <v-form ref="form" @submit.prevent="submitInstructor" lazy-validation>
                            <v-card>
                                <v-card-title>เพิ่มผู้สอน</v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="height: 300px;">
                                    <v-space></v-space>
                                        <label v-if="validateInstructor" class="red--text text--lighten-1">*Instructor not found</label>
                                        <!-- <v-autocomplete
                                            v-model="InstructorSelected"
                                            :items="items"
                                            chips
                                            label="Instructor"
                                            multiple
                                            outlined
                                            required
                                        ></v-autocomplete> -->
                                        <v-text-field
                                            v-model="InstructorSelected"
                                            :rules="[rules.email]"
                                            label="E-mail"
                                            outlined
                                            type="text"
                                        ></v-text-field>

                                        <label v-if="validateSection" class="red--text text--lighten-1">*please select section</label>
                                        <v-select
                                            v-model="SectionSelected"
                                            :items="itemSection"
                                            chips
                                            label="Section"
                                            required
                                            multiple
                                            outlined

                                        ></v-select>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions >
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="clear"
                                        
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        type="submit"
                                    >
                                        Confirm
                                    </v-btn>
                                        
                                </v-card-actions>
                            </v-card>           
                        </v-form>

                    </v-dialog>

                </v-row>
            </v-col>
           
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels v-model="panel" multiple >
                    <v-expansion-panel v-for="(section , i ) in ListInstruction" :key="i">
                        <v-expansion-panel-header class="canvas-outlined">
                            <h2>Sec {{i}}</h2>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col v-if="section.length > 0">
                                    <div v-for="(instruction, j) in section" :key="j">
                                        <v-list-item>
                                            <v-list-item-content>
                                                
                                                <h4><v-icon>mdi-circle-medium</v-icon> {{instruction.email}}</h4>
                                            </v-list-item-content>
                                            
                                            <v-list-item-icon>
                                               <v-icon style="color: rgb(255, 100, 100);" @click="handleDelate(instruction._id)">mdi-close-circle</v-icon>
                                            </v-list-item-icon>
                                        </v-list-item>
                                        
                                    </div>
                                </v-col>
                                <v-col v-else class="text-center">
                                    <h2>Not found</h2>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// import CourseDetailComponent from '../components/CourseDetailComponent'
// import CreateLmsModal from '../components/CreateLmsModal'
import Swal from 'sweetalert2'
import apiGatewayCpmService from "@/services/apiGatewayService"
// import apiLmsManagementService from "@/services/apiLmsManagementService"
// const config = require(`@/config`)

export default {
    name: 'CourseInstrusctorManagement',
    components: {
    },
    data() {
        return {
            ListInstruction:[],
            panel:[],
            items: [],
            itemSection: [],
            dialog:false,
            SectionSelected:[],
            InstructorSelected:[],
            validateInstructor:false,
            validateSection:false,
            rules: {
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
        }
    },
    computed: {
    },
    beforeDestroy() {
    },
    created () {
        this.getInstructorBySection(this.$route.params.courseId)
    },
    methods: {
        async getInstructorBySection(courseId){
            // console.log("Check")
            const listInstructor = await apiGatewayCpmService.getInstructorBySection(localStorage.getItem("accessToken") , courseId);
            // console.log("listInstructor" , listInstructor )

            const listInstructorOfCourse = await apiGatewayCpmService.getInstructor(localStorage.getItem("accessToken") , courseId);
            // console.log("listInstructorOfCourse" , listInstructor )
            delete listInstructor.status
            delete listInstructorOfCourse.status
            
            this.ListInstruction = listInstructor
            let tmp_panel = []
            let tmp_section = []
            let tmp_instruction = []
            for(let i = 0; i < Object.keys(listInstructor).length ; i++){
                tmp_panel.push(i)
            }
            for (const property in listInstructor) {
                tmp_section.push(property)
            }
            for (const instructor in  listInstructorOfCourse){
                tmp_instruction.push(listInstructorOfCourse[instructor].email)
            }
            this.itemSection = tmp_section
            this.panel = tmp_panel
            this.items = tmp_instruction
        },
        async submitInstructor(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/            
            let lengthSec = JSON.parse(JSON.stringify(this.SectionSelected)).length
            let haveUser = false ;
            if(pattern.test(this.InstructorSelected.toLowerCase())){
                const haveUsertmp = await apiGatewayCpmService.GetValidateUserInstructor(localStorage.getItem("accessToken") , this.InstructorSelected )
                // console.log("haveUsertmp:>" , haveUsertmp)
                haveUser = haveUsertmp.status
                if(!haveUser){
                    console.log("validateInstructor")
                    this.validateInstructor = true;
                }else{
                    this.validateInstructor = false;
                }
                
            }else{
                
                this.validateInstructor = true;
            }
            if(lengthSec === 0){
                this.validateSection = true;
            }else{
                this.validateSection = false;
            }
        

            if(pattern.test(this.InstructorSelected) && lengthSec != 0 && haveUser){
                Swal.showLoading()
                let formData = {
                    "instructorsList" : [this.InstructorSelected],
                    "sectionsList" : JSON.parse(JSON.stringify(this.SectionSelected))
                }
                console.log("add:>" , formData)
                const response = await apiGatewayCpmService.AddInstructionInSection(localStorage.getItem("accessToken") ,this.$route.params.courseId , formData )
                console.log("submit",response)
                if(response.status){
                    Swal.fire({
                        icon: 'success',
                        title: 'เพิ่มผู้สอนสำเร็จ',
                    })
                    this.getInstructorBySection(this.$route.params.courseId)
                    this.clear()
                        
                
                }else{
                    this.alertError(response.message)
                }
            }
        },
        clear(){
            this.dialog = false
            this.validateInstructor = false
            this.validateSection = false
            this.InstructorSelected = []
            this.SectionSelected = ['ทั้ง']
        },
        async handleDelate(instructorId){
            Swal.fire({
              icon: 'info',
              title: 'ท่านต้องการลบรายชื่อผู้สอน',
              html: '',
              showCloseButton: true,
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
          }).then((result) => {
              if (result.isConfirmed) {
                const response = this.RemoveInstructor(instructorId);
                if(response){
                    Swal.fire({
                        icon: 'success',
                        title: 'ลบรายชื่อผู้สอนสำเร็จ',
                        html: ''
                    })
                    this.getInstructorBySection(this.$route.params.courseId)
                }else{
                    Swal.fire({
                        icon: 'warning',
                        title: 'ลบรายชื่อผู้สอนไม่สำเร็จ',
                        html: ''
                    })
                }
              }
          })
           
        },
        async RemoveInstructor(instructorId){
            console.log("course" , this.$route.params.courseId , instructorId)
            const response = await apiGatewayCpmService.RemoveInstructionInSection(localStorage.getItem("accessToken"),this.$route.params.courseId,instructorId); 
            if(response){
                return true; 
            }else{
                return false;
            } 

        }
        
    }
}
</script>
